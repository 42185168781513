<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i> แก้ไขข้อมูลร้านค้า</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-store
                :item="item"
                :funcs="funcs"
                :imageDeletes="imageDeletes"
              />
              <div class="float-right d-flex">
                <span
                  ><router-link :to="'/store'" class="nav-link text-underline">
                    กลับ</router-link
                  ></span
                >
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="style_btn"
                  v-if="permission.actionData.flag_update"
                  ><i class="fa fa-check"></i> บันทึก</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formStore from "./form.store";
import * as scripts from "../../../util/scripts";
import StringUtils from "../../../util/StringUtils";
export default {
  name: "store-view-page-store-edit",
  data() {
    return {
      permission: {
        appSlug: "store",
        actionData: {},
      },
      item: {},
      id: "",
      funcs: scripts.funcs,
      imageDeletes: [],
    };
  },
  methods: {
    async editHandler() {
      const file = this.item.logo_url;
      console.log("file: ", file, this.item);
      delete this.item.logo_url;
      const result = await this.HttpServices.putData(
        `/ab_store/${this.item.id}`,
        this.item
      );
      if (result && result.status.code == "200") {
        const s3Auth = await this.HttpServices.authenS3();
        let medias = [];
        if (file) {
          if (s3Auth._info) {
            const accessToken = s3Auth._info.secret.accessToken;

            let params = new FormData();
            params.append("file", file);
            params.append("title", file.name);
            params.append("file_category", "order-payment-slip");
            const resultMedia = await this.HttpServices.postFormDataS3(
              params,
              accessToken
            );
            if (resultMedia.media && resultMedia.media.length > 0) {
              const media = resultMedia.media[0];
              const mediaUpload = {
                id: media.id,
                original_name: media.original_name,
                mime_type: media.mime_type,
                size: media.size,
                url: media.url,
              };

              params = new FormData();
              const thumbFile = await StringUtils.resizeBlobToFile({
                file: file,
                maxSize: 200,
              });
              params.append("file", thumbFile);
              params.append("title", thumbFile.name);
              params.append("file_category", "order-payment-slip");
              let resultThumb = await this.HttpServices.postFormDataS3(
                params,
                accessToken
              );
              if (resultThumb.media && resultThumb.media.length > 0) {
                const mediaThumb = resultThumb.media[0];
                mediaUpload.id_t = mediaThumb.id;
                mediaUpload.original_name_t = mediaThumb.original_name;
                mediaUpload.mime_type_t = mediaThumb.mime_type;
                mediaUpload.size_t = mediaThumb.size;
                mediaUpload.url_t = mediaThumb.url;
              }
              medias.push(mediaUpload);
            }
          } else {
            const mediaUpload = {
              id: file.id,
              original_name: file.original_name,
              mime_type: file.mime_type,
              size: file.size,
              url: file.url,
            };
            if (file.id_t) {
              mediaUpload.id_t = file.id_t;
              mediaUpload.original_name_t = file.original_name_t;
              mediaUpload.mime_type_t = file.mime_type_t;
              mediaUpload.size_t = file.size_t;
              mediaUpload.url_t = file.url_t;
            }
            medias.push(mediaUpload);
          }
          if (medias.length > 0) {
            this.item.logo_url = JSON.stringify(medias[0]);
            await this.HttpServices.putData(
              `/ab_store/${this.item.id}`,
              this.item
            );
            // console.log("update photo: ", this.item);
          }
        }
        if (s3Auth._info) {
          const accessToken = s3Auth._info.secret.accessToken;
          if (this.imageDeletes && this.imageDeletes.length > 0) {
            for (const v of this.imageDeletes) {
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }

        this.AlertUtils.alertCallback("success", `บันทึกสำเร็จ`, () => {
          this.$router.push("/store");
        });
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
    },
    onBackHandler() {
      this.$router.push("/store");
    },

    async getItem() {
      const result = await this.HttpServices.getData(`/ab_store/${this.id}`);
      if (result && result.status.code == "200") {
        this.item = result.data;
        this.item.logo_url = this.item.logo_url
          ? JSON.parse(this.item.logo_url)
          : null;
      } else {
        this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูลผู้ผลิต`, () => {
          this.$router.push("/store");
        });
      }
    },
  },
  components: {
    formStore,
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    this.id = await this.SessionStorageUtils.getSession("page-store");
    await this.getItem();
  },
};
</script>
